import axios from 'axios';
import { getHeaders } from '../../request';
import carregando from '../../signals/carregando';
import { procedimentos, processo, valoresIniciais } from '../../signals/util';
import alerta from '../../signals/alerta';
import Functions from '../../common/Functions';
import { OPCAO_OUTROS_PROCEDIMENTOS, TIPO_ANEXO_SIGLA, TIPO_SOLICITACAO } from '../../common/Constants';
import { NotificationManager } from 'react-notifications';
import { historicoPaciente, parametrosHistoricoPaciente } from '../../signals/apac';

export const baixarArquivoApac = (url, mensagem) => {
    carregando.value = {
        open: true,
        text: mensagem
    }
    axios
        .get(url, { responseType: 'blob', headers: getHeaders() })
        .then((response) => {
            var fileURL = URL.createObjectURL(response.data);
            window.open(fileURL);
        })
        .catch(err => {
            console.log(err);
        }).finally(() => {
            carregando.value = {
                open: false,
                text: ''
            }
        });
};

export const procedimentosApac = (strings) => {
    if (procedimentos.value?.length) {
        return;
    }
    const APAC_API_BASE_URL = global.gConfig.url_base_apac;
    axios
        .get(`${APAC_API_BASE_URL}/administrativo/procedimento-sigtap`, { headers: getHeaders() })
        .then((response) => {
            if (response.data) {
                const dados = response.data;
                procedimentos.value = [...dados, OPCAO_OUTROS_PROCEDIMENTOS];
            };
        })
        .catch(err => {
            alerta.value = {
                ...alerta.value,
                open: true,
                title: strings.erro,
                type: 'error',
                message: strings.erroPesquisaProcedimentoSigtap,
                options: [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                }],
                onClose: () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            };
        });
};

export const tiposAnexoApac = async () => {
    const APAC_API_BASE_URL = global.gConfig.url_base_apac;

    return axios
        .get(`${APAC_API_BASE_URL}/administrativo/tipo-anexo`, { headers: getHeaders() })
        .then((response) => {
            if (response.data) {
                let itens = response.data;
                return itens;
            }
        })
        .catch(err => {
            console.log(err);
            return [];
        });
}

export const verificarApacContinuidade = (strings, values, setFieldValue, setPossibilidadeEscolherApacContinuidade) => {
    if (
        values.paciente?.id &&
        values.procedimento.novoProcedimento &&
        values.procedimento.instituicaoExecutora
    ) {
        const params = {
            idPaciente: values.paciente.id,
            codigoProcedencia: values.procedimento.novoProcedimento.codigo,
            anoMesCompetencia: values.procedimento.novoProcedimento.ano_mes_competencia,
            idInstituicaoExecutora: values.procedimento.instituicaoExecutora.id,
            idProcesso: values.id
        };

        const APAC_API_BASE_URL = global.gConfig.url_base_apac;
        let mensagem;
        axios
            .get(`${APAC_API_BASE_URL}/solicitacao/verificar-apac-continuidade`, { params: params, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    if (response.data.data.length) {
                        setPossibilidadeEscolherApacContinuidade(true);
                        mensagem = strings.informeApacContinuidade;
                    } else {
                        setFieldValue('procedimento.tipoSolicitacao', TIPO_SOLICITACAO.PADRAO);
                        modificarAnexosTipoSolicitacao(TIPO_SOLICITACAO.PADRAO, setFieldValue);
                        setPossibilidadeEscolherApacContinuidade(false);
                        mensagem = strings.informeApacPadrao;
                    }
                };
            })
            .catch(err => {
                console.log('Erro ao verificar a APAC continuidade', err);
            }).finally(() => {
                if (mensagem) {
                    NotificationManager.success(mensagem);
                }
            });
    } else {
        setFieldValue('procedimento.tipoSolicitacao', TIPO_SOLICITACAO.PADRAO);
        setPossibilidadeEscolherApacContinuidade(false);
        modificarAnexosTipoSolicitacao(TIPO_SOLICITACAO.PADRAO, setFieldValue);
    }
}

export const modificarAnexosTipoSolicitacao = (tipoSolicitacao, setFieldValue) => {
    tiposAnexoApac().then(itens => {
        if (tipoSolicitacao === TIPO_SOLICITACAO.PADRAO) {
            setFieldValue('anexos', [
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter(item => item.sigla === TIPO_ANEXO_SIGLA.HISTORICO_SGPE)[0],
                    observacao: ''
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter(item => item.sigla === TIPO_ANEXO_SIGLA.LAUDO_MEDICO)[0],
                    observacao: ''
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter(item => item.sigla === TIPO_ANEXO_SIGLA.EXAMES_OBRIGATORIOS)[0],
                    observacao: ''
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter(item => item.sigla === TIPO_ANEXO_SIGLA.SISREG)[0],
                    observacao: ''
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter(item => item.sigla === TIPO_ANEXO_SIGLA.DOCUMENTO_PESSOAL)[0],
                    observacao: ''
                }
            ]);
        }

        if (tipoSolicitacao === TIPO_SOLICITACAO.CONTINUIDADE) {
            setFieldValue('anexos', [
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter(item => item.sigla === TIPO_ANEXO_SIGLA.LAUDO_MEDICO)[0],
                    observacao: ''
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter(item => item.sigla === TIPO_ANEXO_SIGLA.EXAMES_OBRIGATORIOS)[0],
                    observacao: ''
                }
            ]);
        }
    });
}

export const verificarHistoricoPaciente = (values) => {
    if (
        values.paciente?.id &&
        values.procedimento.novoProcedimento &&
        values.procedimento.instituicaoExecutora &&
        values.procedimento.instituicaoSolicitante
    ) {
        const params = {
            idPaciente: values.paciente.id,
            codigoProcedimento: values.procedimento.novoProcedimento.codigo,
            anoMesCompetencia: values.procedimento.novoProcedimento.ano_mes_competencia,
            idInstituicaoExecutora: values.procedimento.instituicaoExecutora.id,
            idInstituicaoSolicitante: values.procedimento.instituicaoSolicitante.id,
            idProcesso: values.id,
            count: global.gConfig.pagination.count,
            sort: global.gConfig.pagination.sort,
        };

        const APAC_API_BASE_URL = global.gConfig.url_base_apac;
        axios
            .get(`${APAC_API_BASE_URL}/solicitacao`, { params: params, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    historicoPaciente.value = response.data.data;
                    parametrosHistoricoPaciente.value = params;
                };
            })
            .catch(err => {
                console.log('Erro ao verificar a APAC continuidade', err);
                historicoPaciente.value = {};
                parametrosHistoricoPaciente = {};
            });
    }
}

export const solicitacaoPaciente = (id) => {

    const formatarDadosExibicao = (dados) => {
        if (dados.cpf_paciente) {
            dados.cpf_paciente = ('00000000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, "")).slice(-11);
        }

        if (dados.anexos?.length) {
            dados.anexos.forEach(anexo => {
                anexo.idCodificado = window.btoa(anexo.id);
            });
        }
        if (dados.cep_paciente) {
            dados.cpf_paciente = ('00000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, "")).slice(-8);
        }
    }

    axios.get(`${global.gConfig.url_base_apac}/solicitacao/${id}`, { headers: getHeaders() })
        .then((response) => {
            if (response.data) {
                const { data } = response.data;
                formatarDadosExibicao(data);
                valoresIniciais.value = Functions.iniciarValoresSolicitacao(data);
                processo.value = data;
            }
        })
        .catch(err => console.log(err));
}

export const tratarDadoNuloOuVazio = (dado) => {
    if (dado && dado !== '' && dado !== undefined) {
        return dado.trim();
    }

    return null;
}

export const verificarArraySubsetProcessosEncaminhamento = (arrayA, arrayB) => {
    return arrayA.every(a =>
        arrayB.some(b => a.id === b.id)
    ) && arrayA.length > 0;
}